<template>
  <div>
    <c-table
      ref="table"
      title="선택 공정의 KRAS 미조치 Punch-list"
      :columns="grid.columns"
      :data="grid.data"
      gridHeight="450px"
      :filtering="false"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      :hide-bottom="true"
      @linkClick="linkClick"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'process-kras-non-improve',
  props: {
    selectProcessCd: {
      type: String,
      default: function() {
        return '';
      }
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
      selectedProcess: [],
    };
  },
  watch: {
    'selectProcessCd'() {
      this.getList();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
        this.grid.columns = [
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            style: 'width:200px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '분류-유해위험요인',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            label: '원인',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            label: '결과',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            type: 'link',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'ibmStepCd',
            field: 'ibmStepCd',
            label: '진행상태',
            align: 'center',
            style: 'width:100px',
            type: 'tag',
            colorItems: _result,
            sortable: false
          },
          {
            name: 'improveRequest',
            field: 'improveRequest',
            label: '요청부서정보',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            label: '조치완료요청일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ]
      });
      // api scope
      this.listUrl = selectConfig.ram.kras.riskReduce.list.url;
      this.getList();
    },
    getList() {
      if (!this.selectProcessCd) {
        return;
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        processCd: this.selectProcessCd,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data.imprs;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        isSearch: true,
        sopImprovementId: row.sopImprovementId,
        ibmTaskTypeCd: row.ibmTaskTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
